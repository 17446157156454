import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Card from "../../components/blog/card/card"
import BlogNav from "../../components/blog/nav/nav"


export const data = graphql`
  query($slug: String!) {
    dibAuthors(slug: { eq: $slug }) {
      name
      slug
      photo
    }
    allDibPosts(filter: { author: { slug: { eq: $slug } } }) {
      edges {
        node {
          title
          summary
          slug
          publishedAt
          readtime
          featuredImage
          categories {
            title
            slug
          }
          author {
            name
            slug
            photo
          }
        }
      }
    }
  }
`

const ProfilePage = props => {
  const author = props?.data?.dibAuthors
  const posts = props?.data?.allDibPosts?.edges

  return (
    <div>
      <Layout>
        <SEO title="Blog" />
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="blog-header">
              <h1>Posts by {author.name}</h1>
              <BlogNav />
            </div>

            <div className="row">
              {posts.map((post, index) => (
                <div key={index} className="col-sm-6 col-md-4 mb-2">
                  <Card post={post.node} tab={post.node.categories[0]} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ProfilePage
